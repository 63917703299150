.particlesCSS {
  position: absolute !important;
  background-color: #f3f3f3;
  z-index: -1;
}

.cover-heading {
  color: #00378a;
  font-size: 3em;
}

@media (min-width: 768px) {
  .cover-heading {
    font-size: 5em;
  }
}
